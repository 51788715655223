@import "./config.scss";

.mainModal {
  font-family: sans-serif;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  user-select: none;
  outline: none;
  .modclose {
    cursor: pointer;
    background-color: $pnk;
    box-shadow: 0 0 5px $pnk;
    color: $w;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 100px;
    position: absolute;
    right: 1px;
    top: -14px;
    font-size: medium;
  }
  .logRegModal {
    display: flex;
    flex-direction: column;
    background-color: $w;
    box-shadow: 0 0 50px $lG;
    padding: 10px;
    border-radius: 20px;
    .wlcmTxt {
      text-align: center;
      align-self: center;
      padding: 15px 30px;
      background-color: $GW;
      box-shadow: 0 0 10px $GW;
      border-radius: 25px;
      font-family: sans-serif;
      color: $pnk;
      .tsf {
        color: $dGr;
      }
    }
    .regTxt {
      text-align: center;
      .reg {
        cursor: pointer;
        color: $pnk;
        font-weight: 600;
      }
    }
    .loginDiv {
      display: flex;
      align-items: center;
      flex-wrap: wrap-reverse;
      justify-content: center;
      .logFormDiv {
        margin-block: 20px;
        display: flex;
        flex-direction: column;
        padding-inline: 10px;
        .logInputDiv {
          display: flex;
          align-items: center;
          border: 1px solid $dGr;
          box-shadow: 0 0 10px #095f697c;
          border-radius: 22px;
          background-color: $GW;
          margin: 10px;
          .logIcn {
            border-radius: 20px;
            padding: 10px;
            aspect-ratio: 1;
            background-color: $dGr;
            color: $w;
            font-size: 22px;
          }
          .logInput {
            vertical-align: middle;
            font-size: 16px;
            padding-right: 5px;
            background-color: transparent;
            border: 0px;
          }
          .logInput:focus {
            outline: 0px;
          }
        }
        .otpDiv {
          display: flex;
          flex-direction: column;
          align-items: center;
          .verTxt {
            font-size: 12px;
            width: 240px;
            text-align: center;
            margin-bottom: 10px;
          }
          .otp {
            margin-bottom: 20px;
          }
          .otpInput {
            border: 0px;
            border-bottom: 2px solid $dGr;
            box-shadow: 0 0 20px #0003;
            background-color: $GW;
            border-radius: 10px;
            font-size: larger;
            padding: 5px;
          }
          .otpInput:focus {
            outline: 2px solid $pnk;
            border-bottom: 0px;
          }
          .timer {
            padding: 5px 0px;
            font-size: 14px;
          }
          .rsndBtn {
            cursor: pointer;
            background-color: $pnk;
            box-shadow: 0 0 5px $pnk;
            border: 0px;
            padding: 7px 14px;
            border-radius: 20px;
            color: $w;
            font-weight: 600;
            font-size: small;
            margin-bottom: 10px;
          }
          .rsndBtn:hover {
            box-shadow: unset;
          }
        }

        .logBtn {
          align-self: center;
          border: 0px;
          padding: 15px;
          color: $w;
          font-size: 16px;
          font-family: sans-serif;
          border-radius: 20px;
          font-weight: 600;
          cursor: pointer;
          background: linear-gradient(to right, $pnk 50%, $lG 50%);
          background-size: 200% 100%;
          background-position: right;
          transition: all 0.5s ease-out;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        }

        .logBtn:hover {
          background-position: left;
          background-color: $pnk;
          box-shadow: unset;
        }
        .err {
          text-align: center;
          color: $pnk;
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
      .regImg {
        pointer-events: none;
        width: 240px;
        object-fit: contain;
      }
      .logImg {
        pointer-events: none;
        width: 180px;
        object-fit: contain;
      }
    }
  }

  .crsModal {
    width: 80vw;
    box-shadow: 0 0 20px #faadbd;
    background-color: $w;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 90vw;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 80vw;
    }
    .catImg {
      pointer-events: none;
      width: 200px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50px;
    }
    .catName {
      background-color: $w;
      border-radius: 20px;
      box-shadow: 0 0 10px #00000020;
      padding: 10px 20px;
      color: $pnk;
      font-family: sans-serif;
    }
    .Search {
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block: 10px;
      .searchInput {
        width: 70%;
        border: 1px solid #ced4da;
        padding: 15px;
        font-size: 20px;
        margin-block: 5px;
        border-radius: 5px;
      }
      .searchInput:focus {
        outline: none !important;
        border-color: $lG;
        box-shadow: 0 0 5px $lG;
      }
      .searchIcn {
        background-color: $lG;
        color: $w;
        padding: 16px;
        font-size: 20px;
        border-radius: 4px;
      }
    }
    .crsList {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      overflow: scroll;
      max-height: 600px;
      .crsCon {
        margin: 10px;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid $lG;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        font-family: sans-serif;
        overflow: hidden;
        margin: 8px;
        text-align: left;
        display: flex;
        @include media(mobile, tabletPo) {
          width: 40%;
          font-size: 16px;
          padding: 5px 10px;
        }

        @include media(desktop, desktop-big, tabletLa) {
          width: 300px;
          font-size: 18px;
          padding: 10px;
        }
      }
    }
    .adBtn {
      background-color: $pnk;
      padding: 10px 20px;
      color: $w;
      border-radius: 20px;
      box-shadow: 0 0 10px $pnk;
      font-size: large;
      transition: 600ms;
    }
    .adBtn:hover {
      box-shadow: none;
    }
  }

  .xmModal {
    box-shadow: 0 0 20px #faadbd;
    background-color: $w;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    @include media(mobile, tabletPo) {
      width: 90vw;
      max-height: 600px;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 30vw;
      max-height: unset;
    }
    .xmDtlsTxt {
      background-color: $pnk;
      color: $w;
      padding: 10px;
      border-radius: 20px;
      font-size: 24px;
      font-family: sans-serif;
    }
    .xmDtls {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .xcmCon {
        background-color: $GW;
        margin-block: 5px;
        border-radius: 20px;
        @include media(mobile, tabletPo) {
          width: 80vw;
          max-height: 600px;
          padding: 8px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 80%;
          max-height: unset;
        }
        .xmHead {
          font-weight: 600;
          font-family: sans-serif;
          .xmAns {
            font-weight: 400;
          }
        }
      }
      .xmBtns {
        margin-block: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .xmbtn {
          margin: 5px;
          text-decoration: none;
          cursor: pointer;
          padding: 10px;
          color: $w;
          font-weight: 500;
          font-family: sans-serif;
          border: 0px;
          font-size: medium;
          border-radius: 10px;
          background-color: $lG;
          box-shadow: 0 0 10px $lG;
        }
        .xmbtn:hover {
          box-shadow: none;
          background-color: $pnk;
        }
      }
    }
  }

  .ebModal {
    background-color: $w;
    box-shadow: 0 0 20px $pnk;
    border-radius: 20px;
    padding: 10px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 90vw;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 80vw;
    }
    .ebTtl {
      color: $pnk;
      background-color: $w;
      padding: 10px 20px;
      border-radius: 50px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
    .ebList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      overflow-y: scroll;
      max-height: 80vh;
      .ldMrDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        .ldMrBtn {
          cursor: pointer;
          background-color: $lpnk;
          color: $w;
          padding: 10px 20px;
          border-radius: 20px;
          box-shadow: 0 0 10px $pnk;
          margin-block: 10px;
          transition: 600ms;
          font-weight: 600;
          letter-spacing: 0.6px;
        }

        .ldMrBtn:hover {
          background-color: $pnk;
          box-shadow: none;
        }
      }
    }
  }

  .listModMain {
    background-color: $w;
    box-shadow: 0 0 20px $pnk;
    border-radius: 20px;
    padding: 10px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 90vw;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 80vw;
    }
    .lstTtl {
      font-size: 24px;
      font-weight: 600;
      font-family: sans-serif;
      color: #f76a8a;
      background-color: $w;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .lstCon {
      max-height: 600px;
      overflow-y: scroll;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @include media(mobile, tabletPo) {
        max-height: 420px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        max-height: 600px;
      }
      .lstItem {
        display: flex;
        align-items: center;
        width: 360px;
        padding: 10px 20px;
        margin: 10px;
        background-color: $GW;
        box-shadow: 0 0 10px #095f6940;
        font-family: sans-serif;
        border-radius: 50px;
        @include media(mobile, tabletPo) {
          width: 280px;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 360px;
        }
        .lstIcn {
          color: $dGr;
          font-size: 28px;
        }
      }
    }
  }

  .caModMain {
    background-color: $w;
    box-shadow: 0 0 20px $pnk;
    border-radius: 20px;
    padding: 10px;
    width: 56vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media(mobile, tabletPo) {
      width: 90vw;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 56vw;
    }
    .caTtl {
      font-size: 24px;
      font-weight: 600;
      font-family: sans-serif;
      color: #f76a8a;
      background-color: $w;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .caLstCon {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      max-height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      @include media(mobile, tabletPo) {
        max-height: 420px;
      }
      @include media(desktop, desktop-big, tabletLa) {
        max-height: 600px;
      }
      .caLstItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 10px $dGr60;
        background-color: $GW;
        border-radius: 20px;
        width: 240px;
        padding: 5px;
        margin: 10px;
        .caLstImg {
          margin-top: 20px;
          font-family: sans-serif;
          width: 75%;
          aspect-ratio: 1;
          object-fit: contain;
        }
        .caLstName {
          width: 90%;
          color: $w;
          font-size: 20px;
          padding: 10px 20px;
          margin-top: 20px;
          background-color: $dGr;
          border-radius: 20px;
          text-align: center;
        }
      }
    }
  }

  .subModalMain {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $w;
    box-shadow: 0 0 20px $pnk;
    padding: 20px;
    border-radius: 20px;
    .subModTtl {
      font-size: 20px;
      color: $w;
      background-color: $dGr;
      padding: 10px 20px;
      border-radius: 20px;
    }
    .subPlHdCon {
      margin-block: 10px;
      display: flex;
      font-weight: 600;
      font-size: 18px;
      .subplHdOfr,
      .subplHdFr,
      .subplHdM,
      .subplHdY {
        background-color: $pnk;
        box-shadow: 0 0 10px $pnk;
        color: $w;
        margin-inline: 14px;
        border-radius: 10px;
        padding: 10px 0px;
        width: 160px;
        text-align: center;
      }
      .subplHdOfr {
        width: 240px;
      }
    }
    .subPlDtCon {
      border-radius: 20px;
      background-color: $Wpnk;
      box-shadow: 0 0 5px $pnk;
      .subPlItm {
        display: flex;
        font-size: 18px;
        .subPlOfr,
        .subPlFr,
        .subPlM,
        .subPlY {
          text-align: center;
          // margin-block: 5px;
          margin-inline: 14px;
          width: 160px;
          padding: 10px 0px;
        }
        .subPlOfr {
          width: 240px;
          font-weight: 600;
        }
        .subAct {
          background-color: #f66b8986;
          color: $w;
        }
      }
    }
    .subBtnCon {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .subBtn {
        cursor: pointer;
        background-color: $dGr;
        color: $w;
        padding: 10px;
        margin: 20px 0px;
        border-radius: 10px;
        text-align: center;
        box-shadow: 0 0 10px $dGr;
        transition: 0.8s;
      }
      .subBtn:hover {
        box-shadow: none;
      }
    }
    .frBtn {
      bottom: 0;
      border: 1px solid $dGr;
      padding: 5px 10px;
      border-radius: 10px;
      background-color: $GW;
      font-size: 12px;
      font-weight: 500;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      align-self: flex-end;
      transition: 0.8s;
    }
    .frBtn:hover {
      box-shadow: none;
    }
    .frBtn2 {
      text-decoration: none;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 20px;
      background-color: $pnk;
      color: $w;
      box-shadow: 0 0 10px $pnk;
      transition: 0.8s;
      font-weight: 500;
    }
    .frBtn2:hover {
      box-shadow: none;
    }
  }

  .mockLvlDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $w;
    box-shadow: 0 0 20px $GW;
    border-radius: 10px;
    margin: 10px;
    .mLvltxt {
      width: 100%;
      text-align: center;
      border-radius: 10px;
      background-color: $pnk;
      box-shadow: 0 0 10px $pnk;
      margin-block: 0px;
      padding-block: 15px;
      color: $w;
      font-weight: 600;
      font-size: large;
    }
    .mStartD {
      width: 300px;
    }
    .mLvlD {
      display: flex;
      align-items: center;
      .lvlOpt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        aspect-ratio: 1;
        border-radius: 200px;
        box-shadow: 0 0 20px $gr;
        margin-inline: 20px;
        font-size: large;
        transition: 600ms;
        color: $w;
      }
      .Es {
        background-color: $lG;
        box-shadow: 0 0 20px $lG;
      }
      .At {
        background-color: $dG2;
        box-shadow: 0 0 20px $dG2;
      }
      .Hr {
        background-color: $dGr;
        box-shadow: 0 0 20px $dGr;
      }
      .lvlOpt:hover {
        box-shadow: none;
      }
      .mStrtBtn {
        margin-block: 30px;
        background-color: $GW;
        box-shadow: 0 0 10px $dGr;
        color: $dGr;
        border: 1px solid $dGr;
        border-radius: 20px;
        padding: 10px 20px;
        transition: 600ms;
      }
      .mStrtBtn:hover {
        color: $GW;
        background-color: $dGr;
        box-shadow: none;
      }
    }
  }

  .edtProfMain {
    user-select: none;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $w;
    box-shadow: 0 0 50px $lpnk;
    padding: 10px;
    border-radius: 20px;
    @include media(mobile, tabletPo) {
      width: 90vw;
      max-height: 600px;
      overflow-y: scroll;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 30vw;
      max-height: unset;
      overflow-y: unset;
    }
    .editHd {
      border: 1px solid $dG2;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 0 10px $dG2;
      font-size: larger;
      font-weight: 600;
      background-color: $dG2;
      color: $w;
    }
    .edPrImg {
      padding: 4px;
      max-height: 150px;
      background-color: $GW;
      aspect-ratio: 1;
      object-fit: contain;
      border-radius: 100px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      @include media(mobile, tabletPo) {
        max-height: 120px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        max-height: 150px;
      }
    }
    .logInputDiv {
      width: 80%;
      display: flex;
      align-items: center;
      border: 1px solid $dGr;
      box-shadow: 0 0 10px #095f697c;
      border-radius: 22px;
      background-color: $GW;
      margin: 10px;
      .logIcn {
        border-radius: 20px;
        padding: 10px;
        aspect-ratio: 1;
        background-color: $dGr;
        color: $w;
        font-size: 22px;
      }

      .logInput {
        user-select: none;
        width: 80%;
        vertical-align: middle;
        font-size: 16px;
        padding-right: 5px;
        background-color: transparent;
        border: 0px;
        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
          border: 0px;
        }
        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          height: 0px !important;
        }
        .logSelect {
          height: 40px;
          padding-top: 10px;
        }
      }
      .logInput:focus {
        outline: 0px;
      }
    }
    .err {
      text-align: center;
      color: $pnk;
      font-size: 12px;
      margin-bottom: 10px;
    }
    .upBtn {
      padding: 10px 20px;
      color: $pnk;
      background-color: $Wpnk;
      border-radius: 20px;
      box-shadow: 0 0 10px $pnk;
      border: 1px solid $pnk;
      transition: 600ms;
      font-weight: 600;
      cursor: pointer;
    }
    .upBtn:hover {
      box-shadow: none;
      background-color: $pnk;
      color: $Wpnk;
    }
  }

  .tpcPracAnlsMain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    background-color: $w;
    user-select: none;
    flex-direction: column;
    box-shadow: 0 0 50px $lpnk;
    padding: 10px;
    border-radius: 20px;
    .tpcHead {
      background-color: $Wpnk;
      color: $pnk;
      border-radius: 20px;
      box-shadow: 0 0 10px $Wpnk;
      padding: 15px 30px;
      font-size: larger;
      text-align: center;
      font-weight: 600;
    }
    .OaHddiv {
      margin-block: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .OaHdTxt {
        font-size: larger;
        margin-inline: 20px;
        font-weight: 600;
      }
    }
  }

  .ebInfoModal {
    background-color: $w;
    box-shadow: 0 0 20px $pnk;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    @include media(mobile, tabletPo) {
      width: 80vw;
    }
    @include media(desktop, desktop-big, tabletLa) {
      width: 54vw;
    }
    .ebImgDiv {
      display: flex;
      justify-content: center;
      width: 26%;
      background-color: $GW;
      box-shadow: 0 0 20px $gr;
      border-radius: 20px;
      padding: 10px 5px;
      @include media(mobile, tabletPo) {
        width: 50%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 26%;
      }
      .ebImg {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 1;
      }
    }
    .ebInfoDiv {
      background-color: #fcedf070;
      border-radius: 20px;
      display: flex;
      width: 68%;
      flex-direction: column;
      padding-inline: 10px;
      @include media(mobile, tabletPo) {
        width: 100%;
        margin-top: 20px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 68%;
        margin-top: unset;
      }
      .ebName {
        font-size: x-large;
        font-weight: 600;
        color: $pnk;
        margin-block: 15px 0px;
      }
      .ebAuthor {
        margin-top: 5px;
        font-style: italic;
      }
      .ebViews {
        margin-block: 0px 5px;
        color: $dGr;
      }
      .ebPubYear,
      .ebPub,
      .ebSub,
      .ebGnr,
      .ebKeywrd {
        background-color: $lpnk;
        margin-block: 2px;
        padding: 6px 10px;
        font-size: large;
        color: $w;
        border-radius: 20px;
        @include media(mobile, tabletPo) {
          font-size: unset;
        }

        @include media(desktop, desktop-big, tabletLa) {
          font-size: large;
        }
      }
      .ebPubYear,
      .ebGnr {
        background-color: $lG;
      }
      .ebBtnDiv {
        justify-content: space-around;
        display: flex;
        width: 100%;
        align-items: center;
        .ebRead,
        .ebAtb,
        .ebShare {
          cursor: pointer;
          background-color: $dG2;
          color: $w;
          padding: 10px 20px;
          border-radius: 20px;
          box-shadow: 0 0 10px $gr;
          transition: 600ms;
          @include media(mobile, tabletPo) {
            padding: 8px 12px;
            font-size: medium;
          }

          @include media(desktop, desktop-big, tabletLa) {
            padding: 10px 20px;
          }
        }
        .ebRead {
          background-color: $dGr;
          box-shadow: 0 0 10px $dGr;
        }
        .ebShare {
          background-color: $lG;
          box-shadow: 0 0 10px $dGr;
        }
        .ebRead:hover,
        .ebAtb:hover,
        .ebShare:hover {
          box-shadow: none;
        }
      }
    }
  }

  .ebPdfModal {
    box-shadow: 0 0 20px $gr;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .ebPdfVwTlbr {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .ebpdf1 {
        align-items: center;
        width: 2%;
        justify-content: center;
        @include media(mobile, tabletPo) {
          display: none;
        }
        @include media(desktop, desktop-big, tabletLa) {
          display: flex;
        }
      }

      .ebpdf2 {
        align-items: center;
        width: 10%;
        margin-left: 10%;
        @include media(mobile, tabletPo) {
          display: none;
        }
        @include media(desktop, desktop-big, tabletLa) {
          display: flex;
        }
      }

      .ebpdf3 {
        display: flex;
        justify-content: center;
        color: $w;
        align-items: center;

        @include media(mobile, tabletPo) {
          width: 100%;
          margin-right: 0%;
        }
        @include media(desktop, desktop-big, tabletLa) {
          width: 50%;
          margin-right: 20%;
        }
      }

      .ebpdf4 {
        display: flex;
        color: white;
        padding: 0px 10px;
        align-items: center;
        @include media(mobile, tabletPo) {
          display: none;
        }
        @include media(desktop, desktop-big, tabletLa) {
          display: flex;
        }
      }

      .ebpdf4 {
        padding: 0px 2px;
      }
    }
  }
}

.ebPdfMain {
  .rpv-core__viewer {
    height: 100%;
    position: relative;
    align-items: center;
  }
  .rpv-core__viewer1 {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(24px, -50%);
    z-index: 1;
    @include media(mobile, tabletPo) {
      display: none;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: flex;
    }
  }

  .rpv-core__viewer2 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-24px, -50%);
    z-index: 1;
    @include media(mobile, tabletPo) {
      display: none;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: flex;
    }
  }
}

.ebShareModal {
  display: flex;
  width: 400px;
  justify-content: center;
  @include media(mobile, tabletPo) {
    width: 380px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 400px;
  }
  .makeStyles-container-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(120deg, $lpnk 40%, $gr 100%);
    border-radius: 20px;
    border: 0;
    box-shadow: 0 0 10px 5px $lG;
    color: $w;
  }
  .makeStyles-copyContainer-5 {
    border-radius: 20px;
    border: 1px solid $gr;
    background-color: rgba(0, 0, 0, 0.7);
    letter-spacing: 0.6px;
  }
  .makeStyles-copyIcon-7 {
    color: $pnk;
  }
}

.audPlayerDiv {
  background-color: $lpnk;
  width: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px $lG;
  align-items: center;
  @include media(mobile, tabletPo) {
    width: 90vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 600px;
  }
  .metDiv {
    width: 96%;
    display: flex;
    padding-inline: 2%;
    justify-content: space-between;
    padding-block: 10px;
    align-items: center;

    .metImg {
      width: 15%;
      aspect-ratio: 1;
      object-fit: contain;
      padding: 5px;
      background-color: $GW;
      margin: 10px;
      border-radius: 10px;
      box-shadow: -6px 8px 15px 2px $dGr;
      @include media(mobile, tabletPo) {
        width: 20%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 15%;
      }
    }
    .metInfoDiv {
      display: flex;
      width: 78%;
      flex-direction: column;
      align-items: flex-start;
      @include media(mobile, tabletPo) {
        width: 73%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 78%;
      }
      .metTitle,
      .metDate {
        color: $Wpnk;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin-bottom: 0px;
      }
      .metDate {
        font-size: small;
        margin-bottom: 5px;
      }
    }
    .pdSh {
      cursor: pointer;
      background-color: $lG;
      color: $w;
      padding: 8px;
      border-radius: 100px;
      aspect-ratio: 1;
      box-shadow: 0 0 10px $gr;
      font-size: small;
      transition: 600ms;
      margin-block: 10px;
    }
    .pdSh:hover {
      box-shadow: none;
    }
  }
  .rhap_container {
    background-color: $w;
  }
  .rhap_progress-indicator {
    background: $gr !important;
    box-shadow: 0 0 8px $pnk;
  }
  .rhap_progress-filled {
    background-color: $dG2;
  }
  .rhap_download-progress {
    background-color: $GW;
  }
  .rhap_time {
    color: $pnk;
    font-weight: 600;
  }
  .rhap_volume-indicator {
    background-color: $pnk;
    box-shadow: 0 0 10px $gr;
  }
  .rhap_volume-bar {
    background-color: $gr;
  }
  svg {
    color: $dGr;
  }
  .logReqDiv {
    background-color: $w;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logTxt {
      color: $dG2;
      font-size: large;
      font-weight: 600;
      letter-spacing: 0.4px;
    }
    .logBtn {
      cursor: pointer;
      margin-top: 0px;
      background-color: $dGr;
      padding: 10px 20px;
      border-radius: 20px;
      color: $w;
      box-shadow: 0 0 10px $gr;
      transition: 600ms;
    }
    .logBtn:hover {
      box-shadow: none;
    }
  }
}

.NaItm {
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 10px $gr;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 80vh;
  align-items: center;
  margin: 10px 20px;
  background-repeat: no-repeat !important;
  background-size: 100% 40vh !important;
  width: 60vw;
  @include media(mobile, tabletPo) {
    width: 90vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 30vw;
  }
  .naImg {
    width: 100%;
    object-fit: contain;
    height: 36vh;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
  .naBdDiv {
    width: 100%;
    background-color: $w;
    border-radius: 50px 50px 20px 20px;
    display: flex;
    flex-direction: column;
  }
  .naHd {
    padding-inline: 4%;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .naBdCon {
    width: 96%;
    align-self: center;
    .naBd {
      text-align: justify;
      font-size: large;
    }
  }

  .naDt {
    background-color: $GW;
    align-self: flex-end;
    padding: 6px 10px;
    border-radius: 10px;
    font-weight: 600;
    margin-right: 2%;
    letter-spacing: 0.4px;
  }
  .naSrc {
    background-color: $Wpnk;
    padding: 6px;
    align-self: flex-start;
    color: #00000070;
    border-radius: 10px;
    margin-left: 10px;
    font-size: small;
  }

  .naBtn {
    cursor: pointer;
    background-color: $lpnk;
    color: $w;
    padding: 10px 20px;
    font-size: large;
    font-weight: 600;
    letter-spacing: 0.4px;
    border-radius: 20px;
    box-shadow: 0 0 10px $pnk;
    transition: 600ms all ease;
    margin-bottom: 40px;
    align-self: center;
    &:hover {
      background-color: $pnk;
      box-shadow: none;
    }
  }
}

.vidPlayerDiv {
  background-color: $w;
  width: 45vw;
  max-height: 80vh;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-inline: 12px 8px;
  border-radius: 16px;
  box-shadow: 0 0 20px $pnk;
  align-items: center;
  @include media(mobile, tabletPo) {
    width: 92vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 45vw;
  }
  .vid {
    width: 100%;
    border-radius: 10px;
  }
  .vidInfoDiv {
    width: 100%;
    padding-inline: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .subName {
      color: $lG;
      width: 84%;
      margin-block: 5px;
      text-align: center;
      font-weight: bold;
      font-size: large;
    }
    .vidViews {
      margin-block: 5px;
      box-shadow: 0 0 4px $lpnk;
      font-size: small;
      background-color: $Wpnk;
      color: $dGr;
      padding: 5px 10px;
      border-radius: 10px;
      text-align: center;
    }
    .vidName {
      width: 50%;
      margin-block: 0px 10px;
      background-color: $Wpnk;
      box-shadow: 0px 0px 4px $lpnk;
      padding: 10px 20px;
      border-radius: 10px;
      color: $gr;
      font-size: large;
      font-weight: bold;
      @include media(mobile, tabletPo) {
        width: 100%;
        margin-top: 10px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 50%;
        margin-top: 0px;
      }
      .vidBy {
        margin: 0;
        font-size: medium;
        margin-top: 5px;
        font-weight: normal;
        color: $pnk;
      }
    }
    .btnDiv {
      width: 40%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      @include media(mobile, tabletPo) {
        width: 80%;
        margin-inline: 10%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 40%;
        margin-inline: unset;
      }
      .btn {
        cursor: pointer;
        background-color: $dGr;
        color: $w;
        padding: 8px 12px;
        border-radius: 10px;
        box-shadow: 0 0 10px $gr;
        transition: 600ms all;
      }
      .btn:hover {
        background-color: $pnk;
        box-shadow: none;
      }
    }
    .aboutEdu {
      text-align: center;
      padding-inline: 10px;
      width: 100%;
      color: $GW;
      background-color: $gr;
      font-size: large;
      font-weight: bold;
      border-radius: 20px 20px 0 0;
      padding-block: 10px;
      margin: 0px;
    }
    .about {
      width: 100%;
      margin: 0px;
      padding: 10px;
      border-radius: 0 0 20px 20px;
      background-color: $GW;
      text-align: justify;
      .contRd {
        color: $pnk;
        font-weight: bold;
        padding: 4px 8px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .morVidDiv {
    display: flex;
    flex-direction: column;
    width: 38%;
    background-color: $Wpnk;
    border-radius: 20px;
    align-items: center;
  }
}

.eduInfoDiv {
  background-color: $w;
  width: 50vw;
  border-radius: 16px;
  box-shadow: 0 0 20px $pnk;
  @include media(mobile, tabletPo) {
    width: 96vw;
  }

  @include media(desktop, desktop-big, tabletLa) {
    width: 50vw;
  }
  .eduHd {
    width: 100%;
    text-align: center;
    background-color: $pnk;
    margin-top: 0px;
    padding-block: 10px;
    border-radius: 10px 10px 0 0;
    color: $GW;
    font-weight: bold;
    font-size: larger;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
  }
  .conDiv {
    border-radius: 0 0 10px 10px;
    background: linear-gradient(120deg, $Wpnk 0%, $w 50%, $GW 100%);
    max-height: 80vh;
    overflow: hidden scroll;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    @include media(mobile, tabletPo) {
      flex-wrap: wrap-reverse;
    }

    @include media(desktop, desktop-big, tabletLa) {
      flex-wrap: wrap;
    }
    .eduInfo {
      width: 60%;
      @include media(mobile, tabletPo) {
        width: 90%;
      }

      @include media(desktop, desktop-big, tabletLa) {
        width: 60%;
      }
      .eduName {
        margin-block: 15px 10px;
        font-size: x-large;
        font-weight: 600;
        color: $gr;
      }
      .eduAbout {
        background-color: $GW;
        color: $dGr;
        padding: 10px;
        border-radius: 10px;
        text-align: justify;
        box-shadow: 0 0 5px $lpnk;
        @include media(mobile, tabletPo) {
          font-size: small;
        }

        @include media(desktop, desktop-big, tabletLa) {
          font-size: unset;
        }
      }
      .eduCrsDiv {
        border-radius: 10px;
        padding: 10px;
        background-color: $Wpnk;
        box-shadow: 0 0 5px $lG;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        .eduCrs {
          display: flex;
          justify-content: space-between;
          margin-block: 8px;
          align-items: center;
          @include media(mobile, tabletPo) {
            margin-block: 5px;
          }

          @include media(desktop, desktop-big, tabletLa) {
            margin-block: 8px;
          }
          .crsHd {
            font-weight: 600;
            width: 25%;
            @include media(mobile, tabletPo) {
              font-size: small;
            }

            @include media(desktop, desktop-big, tabletLa) {
              font-size: unset;
            }
          }
          .crs {
            font-size: small;
            width: 75%;
            @include media(mobile, tabletPo) {
              font-size: small;
            }

            @include media(desktop, desktop-big, tabletLa) {
              font-size: unset;
            }
          }
        }
      }
    }
    .eduDP {
      width: 30%;
      .eduImg {
        margin-top: 25%;
        pointer-events: none;
        width: 100%;
        border-radius: 500px;
        box-shadow: 0 0 10px $gr;
        aspect-ratio: 1;
      }
    }
  }
}

////////////////////////////////////// Report Modal ///////////////////////////
.rprtMdl {
  background-color: $w;
  width: 31vw;
  max-height: 80vh;
  overflow: hidden scroll;
  border-radius: 20px;
  box-shadow: 0 0 20px $lpnk;
  padding: 2% 4%;
  display: flex;
  flex-direction: column;
  .rprtTxt {
    color: #393939;
    font-size: 30px;
    font-family: "Times New Roman", Times, serif;
    font-weight: 600;
    margin-block: 10px 0px;
  }
  .rprtTxt2 {
    margin-block: 10px;
    font-size: x-large;
    font-family: "Times New Roman", Times, serif;
  }
  .rprtRsnDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .rsnTxt {
      color: #393939;
      font-weight: 600;
      background-color: $GW;
      padding: 14px 16px;
      margin: 8px 6px;
      box-shadow: 0 0 6px $GW;
      border-radius: 100px;
      letter-spacing: 0.4px;
      cursor: pointer;
      transition: 600ms all;
    }
    .rsnTxt:hover {
      box-shadow: none;
    }
    .rsnAt {
      background-color: $lpnk;
      color: $w;
      box-shadow: none;
    }
  }
  .rpTxt3 {
    margin-top: 0px;
    color: #a8a8a8;
    font-size: large;
  }
  .rsnTxt {
    color: #393939;
    font-weight: 600;
    background-color: $GW;
    padding: 14px 16px;
    margin: 0px;
    border-radius: 20px;
    letter-spacing: 0.4px;
    border: 0px;
    resize: vertical;
  }
  .rsnTxt:focus {
    outline: 0px;
  }
  .submitRptBtn {
    width: 100%;
    text-align: center;
    background-color: $pnk;
    color: $Wpnk;
    font-weight: 600;
    font-size: larger;
    padding-block: 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px $lpnk;
    cursor: pointer;
    transition: 600ms all;
  }
  .submitRptBtn:hover {
    box-shadow: none;
  }
}
//////////////////////////////// Loading Modal /////////////////////////////////////////
.dlMdlCon {
  // background: linear-gradient(160deg, $Wpnk 40%, $lG 100%);
  background: $w;
  border: solid 3px $gr;
  border-right-color: $pnk;
  border-bottom-color: $pnk;
  max-height: 80vh;
  border-radius: 100px;
  box-shadow: 0 0 10px $GW;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;

  .ldTxt {
    margin-bottom: 10px;
    font-size: xx-large;
    font-weight: bold;
    font-style: italic;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: $gr;
  }

  .loader {
    width: 60px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background: linear-gradient(0deg, $lpnk 30%, #0000 0 70%, $lG 0) 50%/8% 100%,
      linear-gradient(90deg, $lpnk 30%, #0000 0 70%, $lG 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
  }
  .loader::before,
  .loader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
  }
  .loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }

  // .loader {
  //   width: fit-content;
  //   font-weight: bold;
  //   font-family: monospace;
  //   font-size: 30px;
  //   background: radial-gradient(circle closest-side, $pnk 94%, #0000)
  //     right/calc(200% - 1em) 100%;
  //   animation: l24 1s infinite alternate linear;
  // }
  // .loader::before {
  //   content: "Loading...";
  //   line-height: 1em;
  //   color: #0000;
  //   background: inherit;
  //   background-image: radial-gradient(circle closest-side, #fff 94%, $pnk);
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
  // @keyframes l24 {
  //   100% {
  //     background-position: left;
  //   }
  // }
}
