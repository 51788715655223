@import "./config.scss";

.quesDiv {
  padding: 50px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  @include media(mobile, tabletPo) {
    padding: 10px;
    padding-bottom: 10px;
  }
  @include media(desktop, desktop-big, tabletLa) {
    padding: 50px;
    padding-bottom: 10px;
  }
  // border:2px solid #000;
  .quesTopicDiv {
    background-color: $Wpnk;
    box-shadow: 0 0 10px $Wpnk;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-inline: 20px;
    font-size: larger;
    font-weight: 500;

    .quesDif {
      color: $pnk;
      font-weight: 600;
    }
  }
  .quesNum {
    font-weight: 600;
    margin: 4px;
  }

  .quesImg {
    width: 80%;
    object-fit: contain;
    align-self: center;
  }

  .quesTxt {
    font-size: x-large;
    font-weight: 500;
  }

  .quesOptDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-block: 20px;
    @include media(mobile, tabletPo) {
      justify-content: center;
    }
    @include media(desktop, desktop-big, tabletLa) {
      justify-content: space-between;
    }
    .quesOpt,
    .exmOpt {
      text-align: center;
      margin: 5px 5px;
      cursor: pointer;
      border-radius: 50px;
      padding: 20px 10px;
      background-color: $GW;
      box-shadow: 0 0 20px $GW;
      font-size: large;
      min-width: 120px;
      max-width: 300px;
      font-weight: 500;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        margin: 5px 5px;
        font-size: unset;
        min-width: 50px;
        max-width: 300px;
        padding: 15px 10px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        margin: 10px 10px;
        font-size: unset;
        min-width: 120px;
        max-width: 300px;
        padding: 20px 10px;
      }
    }
    .quesOpt:hover,
    .quesWr {
      background-color: $pnk;
      color: $w;
    }
    .quesAt {
      background-color: $gr;
      color: $w;
    }
    .quesCr {
      background-color: #7ac021;
      color: $w;
    }
  }
  .btnDiv {
    display: flex;
    justify-content: space-evenly;
  }
  .nxtBtn {
    cursor: pointer;
    background-color: $dGr;
    align-self: center;
    color: $w;
    padding: 15px 30px;
    border-radius: 20px;
    font-size: large;
    font-weight: 500;
    box-shadow: 0 0 10px $dGr;
    border: 2px solid $dGr;
    transition: 600ms;
    @include media(mobile, tabletPo) {
      padding: 12px 12px;
      font-size: unset;
    }

    @include media(desktop, desktop-big, tabletLa) {
      padding: 15px 30px;
      font-size: large;
    }
  }
  .nxtBtn:hover {
    box-shadow: none;
    background-color: $w;
    color: $dGr;
    border: 2px solid $dGr;
  }

  .mob {
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    // font-size: large;
    border-radius: 20px;
    box-shadow: 0 0 20px $GW;
    margin-block: 10px;
    font-weight: 600;
    @include media(mobile, tabletPo) {
      display: flex;
    }
    @include media(desktop, desktop-big, tabletLa) {
      display: none;
    }
  }
  .cr {
    color: #7ac021;
  }

  .wr {
    color: $pnk;
  }

  .sk {
    color: #8b65bf;
  }

  .rvwBtnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10px;
    .rvwBtn {
      cursor: pointer;
      box-shadow: 0 0 10px $dGr;
      font-size: large;
      font-weight: 500;
      border: 1px solid $dGr;
      border-radius: 10px;
      padding: 10px 10px;
      transition: 600ms;
      margin-bottom: 0px;
      @include media(mobile, tabletPo) {
        padding: 10px 20px;
        font-size: unset;
      }

      @include media(desktop, desktop-big, tabletLa) {
        padding: 15px 30px;
        font-size: large;
      }
    }
    .rvwBtn:hover {
      background-color: $dGr;
      color: $GW;
      box-shadow: none;
    }
    .scoreDiv {
      align-self: center;
      width: 50%;
      display: flex;
      justify-content: space-evenly;
      font-size: larger;
      border-radius: 20px;
      box-shadow: 0 0 20px $GW;
      margin-block: 10px;
      font-weight: 600;
      @include media(mobile, tabletPo) {
        display: none;
      }

      @include media(desktop, desktop-big, tabletLa) {
        display: flex;
      }
    }
  }
  .quesScrol {
    height: 30vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.quesMain {
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  margin-block: 20px;
  .qSubName {
    align-self: center;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 20px $lG;
    font-size: xx-large;
    font-weight: 500;
  }
  .rpBtn {
    cursor: pointer;
    background-color: $pnk;
    align-self: center;
    color: $w;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: large;
    font-weight: 500;
    box-shadow: 0 0 10px $pnk;
    transition: 600ms;
  }

  .rpBtn:hover {
    box-shadow: none;
  }
}

.mquM {
  width: 69%;
  display: flex;
  flex-direction: column;
  @include media(mobile, tabletPo) {
    width: 96%;
  }
  @include media(desktop, desktop-big, tabletLa) {
    width: 69%;
  }
  .mSecDiv {
    display: flex;
    flex-wrap: wrap;
    background-color: $Wpnk;
    box-shadow: 0 0 10px $Wpnk;
    border-radius: 10px;

    @include media(mobile, tabletPo) {
      width: 95%;
      padding-block: 4px;
      justify-content: space-evenly;
    }

    @include media(desktop, desktop-big, tabletLa) {
      width: 100%;
      padding-block: 10px;
      justify-content: unset;
    }
    .mSec {
      background-color: $gr;
      color: $Wpnk;
      margin: 10px;
      border-radius: 10px;
      box-shadow: 0 0 10px $gr;
      transition: 600ms;
      @include media(mobile, tabletPo) {
        font-weight: 550;
        padding: 6px 16px;
      }

      @include media(desktop, desktop-big, tabletLa) {
        font-weight: 550;
        padding: 10px 25px;
      }
    }
    .mSec:hover {
      background-color: $dG2;
      box-shadow: 0 0 10px $dG2;
    }
    .actSec {
      background-color: $pnk;
      box-shadow: none;
    }
  }
  .quesScrol {
    height: 40vh;
  }
}

.pracQuesMain {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-inline: 5%;
  .quesHead {
    width: 96%;
    display: flex;
    justify-content: space-between;
    background-color: $GW;
    padding-inline: 2%;
    align-self: center;
    border-radius: 20px;
    box-shadow: 0 0 5px $gr;
    font-size: larger;
    align-items: center;
    @include media(mobile, tabletPo) {
      font-size: unset;
    }

    @include media(desktop, desktop-big, tabletLa) {
      font-size: larger;
    }
    .txt {
      width: 50%;
      text-align: center;
      font-weight: 600;
      .es {
        color: $lG;
      }
      .md {
        color: $gr;
      }
      .hd {
        color: $dG2;
      }
    }
  }
}

.quesScrol {
  height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
